<template>
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
		<div class="card-title align-items-start flex-column">
			<h3 class="card-label font-weight-bolder text-dark">{{$t('SETTINGS.PAYMENT.MSG_TITLE_PAYMENT')}}  </h3>
			<span class="text-muted font-weight-bold font-size-sm mt-1">{{$t('SETTINGS.PAYMENT.MSG_DESC_PAYMENT')}} </span>
		</div>
		<div class="card-toolbar">
			<button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
				Valider
			</button>
		</div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
	  
	  
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('MSG_BANK_TRANSFER')}}</label>
			<div class="col-lg-9 col-xl-9">
				<span class="switch switch-sm">
					<label>
						<input ref="ref_bank_transfer" type="checkbox" @change="ChangeBankTransfer()" :checked="currentUserPersonalInfo.settings.bank_transfer" name="email_notification_1"/>
						<span></span>
					</label>
				</span>
				
			</div>
		</div>
		<div  v-if="bank_transfer">
				<div class="col-lg-12 col-xl-12">
					<span>
						{{$t('SETTINGS.PAYMENT.MSG_DESC_BANK_TRANSFER')}}
					</span>
				</div>
			<div class="form-group row">
				<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_RIP')}}</label>
				<div class="col-lg-9 col-xl-6">
					<input ref="ref_rip" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.rip"/>
					<span class="form-text text-muted">{{$t('SETTINGS.PAYMENT.MSG_RIP_DESC')}}</span>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_IBAN')}}</label>
				<div class="col-lg-9 col-xl-6">
					<input ref="ref_iban" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.iban"/>
					<span class="form-text text-muted">{{$t('SETTINGS.PAYMENT.MSG_IBAN_DESC')}}</span>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_SWIFT')}}</label>
				<div class="col-lg-9 col-xl-6">
					<input ref="ref_swift" class="form-control form-control-lg form-control-solid" type="text"  v-bind:value="currentUserPersonalInfo.settings.code_swift_bic"/>
					<span class="form-text text-muted">{{$t('SETTINGS.PAYMENT.MSG_SWIFT_DESC')}}</span>
				</div>
			</div>
			
		</div>
		<hr>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_POSTAL_ORDER')}}</label>
			<div class="col-lg-9 col-xl-6">
				<span class="switch switch-sm">
					<label>
						<input type="checkbox" ref="ref_postal_order" :checked="currentUserPersonalInfo.settings.postal_order" name="email_notification_2"/>
						<span></span>
					</label>
				</span>
			</div>
		</div>
		<hr>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_CASH')}}</label>
			<div class="col-lg-9 col-xl-6">
				<span class="switch switch-sm">
					<label>
						<input type="checkbox" ref="ref_cash" :checked="currentUserPersonalInfo.settings.cash" name="email_notification_3"/>
						<span></span>
					</label>
				</span>
			</div>
		</div>
		<hr>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_TICKET_RESTAURANT')}}</label>
			<div class="col-lg-9 col-xl-6">
				<span class="switch switch-sm">
					<label>
						<input type="checkbox" ref="ref_ticket_restaurant" :checked="currentUserPersonalInfo.settings.ticket_restaurant" name="email_notification_3"/>
						<span></span>
					</label>
				</span>
			</div>
		</div>
		<hr>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_BANK_CARDS')}}</label>
			<div class="col-lg-9 col-xl-6">
				<span class="switch switch-sm">
					<label>
						<input type="checkbox" ref="ref_clictopay" :checked="currentUserPersonalInfo.settings.clictopay" name="email_notification_3" @change="ChangeClictopayShow()"/>
						<span></span>
					</label>
				</span>
			</div>
		</div>
		<div v-if="clictopay_Show">
			<div class="col-lg-12 col-xl-12">
				<span v-html="$t('MSG_CLICTOPAY_DESC')">
				</span>
			</div>
			<div class="form-group row">
				<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_CLICTOPAY_LOGIN')}}</label>
				<div class="col-lg-9 col-xl-6">
					<input ref="ref_clictopay_login" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.clictopay_login"/>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_CLICTOPAY_PASSWORD')}}</label>
				<div class="col-lg-9 col-xl-6">
					<input ref="ref_clictopay_password" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.clictopay_password"/>
				</div>
			</div>
		</div>
		<hr>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.PAYMENT.MSG_D17')}}</label>
			<div class="col-lg-9 col-xl-6">
				<span class="switch switch-sm">
					<label>
						<input type="checkbox" ref="ref_digipostbank" @change="ChangeDigipostBank()" :checked="currentUserPersonalInfo.settings.digipostbank" name="email_notification_6"/>
						<span></span>
					</label>
				</span>
			</div>
		</div>
		<!--<hr>
		<div class="form-group row"  v-if="digipostbank">
				<div class="col-lg-12 col-xl-12">
					<span>
						D17 est une application mobile pour Smartphone et IOS associée à votre compte e Dinar (Smart, PRO, Jeune, Digicard,...) et qui offre plusieurs services.<br>
						L’appli D17 vous permet la réalisation des paiements via QR code en toute sécurité et rapidité.
					</span>
				</div>
				<br>
				<label class="col-xl-3 col-lg-3 col-form-label text-right">D17 - QrCode</label>
				<div class="col-lg-9 col-xl-6">
					<div class="image-input image-input-outline" id="kt_profile_avatar">
						<div class="image-input-wrapper" :style="{ backgroundImage: `url(${current_photo})` }"></div>
						<label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="Change avatar">
							<i class="fa fa-pen icon-sm text-muted"></i>
							<input type="file" name="qrcode_avatar" id="qrcode_avatar" accept=".png, .jpg, .jpeg" @change="onFileChange($event)"/>
							<input type="hidden" name="profile_avatar_remove" />
						</label>
						<span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="Cancel avatar">
							<i class="ki ki-bold-close icon-xs text-muted"></i>
						</span>
						<span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove avatar" @click="current_photo = null">
							<i class="ki ki-bold-close icon-xs text-muted"></i>
						</span>
					</div>
					<span class="form-text text-muted">Types de fichiers autorisés: png, jpg, jpeg.</span>
				</div>
			</div>-->

      
        </div>
    </form>
    <!--end::Form-->
  </div>
</template>
 
<script>
import { mapGetters } from "vuex";
//import { UPDATE_ACCOUNT_INFO } from "@/core/services/store/profile.module";
import axios from "axios";
import Swal from "sweetalert2";
export default {
	name: "EmailSettings",
	data() {
		return {
			clictopay_Show: false,
			bank_transfer: false,
			postal_order: false,
			cash: false,
			digipostbank: false,
			digipostbankPic: false,
			current_photo: null,
		};
	},
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		//this.getCurrencyCountry();
		this.clictopay_Show= this.currentUserPersonalInfo.settings.clictopay;
		this.bank_transfer= this.currentUserPersonalInfo.settings.bank_transfer;
		this.postal_order= this.currentUserPersonalInfo.settings.postal_order;
		this.cash= this.currentUserPersonalInfo.settings.cash;
		this.digipostbank= this.currentUserPersonalInfo.settings.digipostbank;
		//this.digipostbankPic= this.currentUserPersonalInfo.settings.digipostbank;
		//this.current_photo = this.currentUserPersonalInfo.settings.qrcode_d17;
		console.log(this.currentUserPersonalInfo.settings.qrcode_d17);
	},
	methods: {
		ChangeBankTransfer() {
			this.bank_transfer = !this.bank_transfer; 
		},
		ChangeClictopayShow() {
			this.clictopay_Show = !this.clictopay_Show; 
		},
		ChangeDigipostBank() {
			this.digipostbank = !this.digipostbank; 
		},
		onFileChange(e) {
			//this.digipostbankPic = true; 
			const file = e.target.files[0];

			if (typeof FileReader === "function") {
				const reader = new FileReader();

				reader.onload = event => {
					this.current_photo = event.target.result;
				};

				 reader.readAsDataURL(file);
			}
		},
		save() {
			const submitButton = this.$refs["kt_save_changes"];
			submitButton.classList.add("spinner", "spinner-light", "spinner-right");
			var bank_transfer = this.$refs.ref_bank_transfer.checked;
			var digipostbank = this.$refs.ref_digipostbank.checked;
			var cash = this.$refs.ref_cash.checked;
			var postal_order = this.$refs.ref_postal_order.checked;
			var clictopay = this.$refs.ref_clictopay.checked;
			var ticket_restaurant = this.$refs.ref_ticket_restaurant.checked;
			
			var ref_rip = this.currentUserPersonalInfo.settings.rip;
			var ref_iban = this.currentUserPersonalInfo.settings.iban;
			var ref_swift = this.currentUserPersonalInfo.settings.code_swift_bic;
			
			if(bank_transfer == true){
				ref_rip = this.$refs.ref_rip ? this.$refs.ref_rip.value :"";
				ref_iban = this.$refs.ref_iban ? this.$refs.ref_iban.value : "";
				ref_swift = this.$refs.ref_swift ? this.$refs.ref_swift.value : "";
				if(ref_rip == ""){
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.PAYMENT.MSG_ERROR_RIP"),"error");
					return;
				}
				if(ref_iban == ""){
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.PAYMENT.MSG_ERROR_IBAN"),"error");
					return;
				}
				if(ref_swift == ""){
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.PAYMENT.MSG_ERROR_SWIFT"),"error");
					return;
				}
			}
			var clictopay_login = this.currentUserPersonalInfo.settings.clictopay_login;
			var clictopay_password = this.currentUserPersonalInfo.settings.clictopay_password;
			if(clictopay == true){
				clictopay_login = this.$refs.ref_clictopay_login ? this.$refs.ref_clictopay_login.value :"";
				clictopay_password = this.$refs.ref_clictopay_password ? this.$refs.ref_clictopay_password.value : "";
				if(clictopay_login == ""){
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.PAYMENT.MSG_ERROR_CLICTOPAY_LOGIN"),"error");
					return;
				}
				if(clictopay_password == ""){
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.PAYMENT.MSG_ERROR_CLICTOPAY_PASSWORD"),"error");
					return;
				}
			}
			var data = 	{
							bank_transfer:bank_transfer,
							digipostbank:digipostbank,
							cash:cash,
							clictopay:clictopay,
							ticket_restaurant:ticket_restaurant,
							postal_order:postal_order,
							rip:ref_rip,
							iban:ref_iban,
							swift:ref_swift,
							clictopay_login:clictopay_login,
							clictopay_password:clictopay_password,
						}
			axios.post("/klik/customer/update/settings/paiement",data).then((response) => {
					console.log(response);
					this.UpdateSession;
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.PAYMENT.MSG_SUCCESS_OPERATION"),"info");
					/*if(this.digipostbankPic)
						this.submitPicture();*/
					submitButton.classList.remove(
						"spinner",
						"spinner-light",
						"spinner-right"
					);
				}).catch((errors) => {    
					console.log(errors)  ;
					localStorage.clear();				
					this.$router.push('/login');
				});
			
			
		},
		SwalShow(title,text,icon) {
			Swal.fire({
						title: title,
						text: text,
						icon: icon,
						confirmButtonClass: "btn btn-secondary",
						heightAuto: false
					}); 
		},
		submitPicture() {
			var ext = ['jpeg',"jpg","png",'JPEG',"JPG","PNG"];
			var formData = new FormData();
			const imagefile = document.querySelector('#qrcode_avatar');
			for (var x = 0; x < imagefile.files.length; x++) {
				var nameFileExt = imagefile.files[x].name.split(".");
				nameFileExt = nameFileExt[nameFileExt.length-1];
				if(ext.indexOf(nameFileExt) == -1){
					alert("Impossible de lire le fichier. Le format n’est pas pris en charge");
					return;
				}
				formData.append("file", imagefile.files[x], imagefile.files[x].name);
			}

			axios.post("/klik/paiement/img",formData, {
				headers: {
				  'Content-Type': 'multipart/form-data'
			}})
			.then((response) => {
				console.log(response);
				
			}).catch((errors) => {
				console.log(errors)  ;
			});
			
		},
	},
	computed: {
		...mapGetters(["currentUserPersonalInfo"]),
		...mapGetters(["currentUserAccountInfo"]),
		...mapGetters(["UpdateSession"]),
		photo() {
			return this.current_photo;
		}
	}
};
</script>

<style scoped></style>
