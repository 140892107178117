<template>
  <!--begin::Card-->
  <div class="card card-custom">
	
    <!--begin::Header-->
		<div class="card-header py-3">
		<div class="card-title align-items-start flex-column">
			<h3 class="card-label font-weight-bolder text-dark">{{$t('SETTINGS.SECURITY.MSG_TITLE_SECURITY')}}</h3>
			<span class="text-muted font-weight-bold font-size-sm mt-1">{{$t('SETTINGS.SECURITY.MSG_DESC_SECURITY')}}</span>
		</div>
      <div class="card-toolbar">
        <button
          type="submit"
          class="btn btn-success mr-2"
          @click="SubmitChangePassword()"
          ref="kt_save_changes"
        >
           {{$t('SETTINGS.SECURITY.MSG_TO_VALIDATE')}} 
        </button>
        <!--<button type="reset" class="btn btn-secondary" @click="cancel()">
          Annuler
        </button>-->
      </div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
	
	
	
	
    <form class="form" id="kt_password_change_form">
      <div class="card-body">
		<div class="alert alert-custom alert-light-info fade show mb-10" role="alert" v-if="!valid">
		<div class="alert-icon">
		<span class="svg-icon svg-icon-3x svg-icon-info">
			<!--begin::Svg Icon | path:assets/media/svg/icons/Code/Info-circle.svg-->
			<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
				<g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
					<rect x="0" y="0" width="24" height="24" />
					<circle fill="#000000" opacity="0.3" cx="12" cy="12" r="10" />
					<rect fill="#000000" x="11" y="10" width="2" height="7" rx="1"/>
					<rect fill="#000000" x="11" y="7" width="2" height="2" rx="1"/>
				</g>
			</svg>
			<!--end::Svg Icon-->
		</span>
		</div>
		<div class="alert-text font-weight-bold">
			<ul style="margin: 0 0 0 22px;">
				<li v-for="errors in errorsPassword" :key="errors">{{errors}}</li>
			</ul>
		</div>
		<div class="alert-close">
		<button type="button" class="close" data-dismiss="alert" aria-label="Close">
			<span aria-hidden="true">
				<i class="ki ki-close"></i>
			</span>
		</button>
		</div>
	</div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >{{$t('SETTINGS.SECURITY.MSG_NEW_SECURITY')}} </label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              name="new_password"
              ref="new_password"
			  @change="VerifChangePassword()"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-alert"
            >{{$t('SETTINGS.SECURITY.MSG_CONFIRM_SECURITY')}}</label
          >
          <div class="col-lg-9 col-xl-6">
            <input
              type="password"
              class="form-control form-control-lg form-control-solid"
              value=""
              name="verify_password"
              ref="verify_password"
              @change="VerifChangePassword()"
            />
          </div>
        </div>
      </div>
    </form>
    <!--end::Form-->
  </div>
</template>

<script>

import axios from "axios";
import Swal from "sweetalert2";
export default {
  name: "ChangePassword",
  data() {
    return {
			password: "",
			status: "",
			valid: true,
			rules: [
				{ message:'Une lettre minuscule requise.', regex:/[a-z]+/ },
				{ message:"Une lettre majuscule requise.",  regex:/[A-Z]+/ },
				{ message:"8 caractères minimum.", regex:/.{8,}/ },
				{ message:"Un numéro requis.", regex:/[0-9]+/ }
			],
			errorsPassword : [],
			errors : [],
    };
  },
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		
		this.rules = [
				{ message:this.$t("SETTINGS.SECURITY.MSG_LOWERCASE_REQUIRED"), regex:/[a-z]+/ },
				{ message:this.$t("SETTINGS.SECURITY.MSG_UPPERCASE_REQUIRED"),  regex:/[A-Z]+/ },
				{ message:this.$t("SETTINGS.SECURITY.MSG_8_REQUIRED"), regex:/.{8,}/ },
				{ message:this.$t("SETTINGS.SECURITY.MSG_NUMBER_REQUIRED"), regex:/[0-9]+/ }
			];
		
	},
	methods: {
		VerifChangePassword() {
			this.errorsPassword = [];
			this.valid = true;
			var new_password = this.$refs.new_password.value;
			var verify_password = this.$refs.verify_password.value;
			for (let condition of this.rules) {
				if (!condition.regex.test(new_password)) {
					this.errorsPassword.push(condition.message);
					//this.ErrorsJoin();
					this.valid = false;
				}
			}
			if(new_password != verify_password){
				//this.SwalShow("","Les mots de passe ne correspondent pas.","error");
				this.errorsPassword.push(this.$t("SETTINGS.SECURITY.MSG_PASSWORD_REPASSWORD"));
				//this.ErrorsJoin();
				this.valid = false;
				return;
			}
			
			/*var strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");
			
			if(strongRegex.test(new_password)) {
				this.valid = false;
			}*/
		},
		SubmitChangePassword() {
			var new_password = this.$refs.new_password.value;
			//var verify_password = this.$refs.verify_password.value;
			/*if(new_password != verify_password){
				this.SwalShow("","Les mots de passe ne correspondent pas.","error");
				this.valid = false;
				return;
			}*/
			this.VerifChangePassword();
			if(!this.valid){
				return false;
			}
			
			var data = {password:new_password};
			axios.post("/klik/customer/update/password",data).then((response) => {
					console.log(response);
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.SECURITY.MSG_SUCCESS_OPERATION"),"info");
				}).catch((errors) => {    
					console.log(errors)  ;
					localStorage.clear();				
					this.$router.push('/login');
				});
		},
		cancel() {
			this.$refs.new_password.value = "";
			this.$refs.verify_password.value = "";
		},
		/*ErrorsJoin() {
			this.errors = this.errorsPassword.join("<br>");
		},*/
		SwalShow(title,text,icon) {
			Swal.fire({
					title: title,
					text: text,
					icon: icon,
					confirmButtonClass: "btn btn-secondary",
					heightAuto: false
				}); 
		},
    }
};
</script>

<style scoped></style>
