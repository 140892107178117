<template>
  <!--begin::Card-->
  <div class="card card-custom">
	
    <!--begin::Header-->
		<div class="card-header py-3">
		<div class="card-title align-items-start flex-column">
			<h3 class="card-label font-weight-bolder text-dark">{{$t('SETTINGS.DOMAIN.MSG_TITLE_DOMAIN')}}</h3>
			<span class="text-muted font-weight-bold font-size-sm mt-1">{{$t('SETTINGS.DOMAIN.MSG_DESC_DOMAIN')}}</span>
		</div>
		<div class="card-toolbar">
			<button type="submit" class="btn btn-success mr-2" @click="SubmitDomain()" ref="kt_save_changes">
				{{$t('SETTINGS.DOMAIN.MSG_TO_VALIDATE')}}
			</button>
			<!--<button type="reset" class="btn btn-secondary" @click="cancel()">
				Annuler
			</button>-->
		</div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
	<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.DOMAIN.MSG_SHOP_ADDRESS_LOCAL')}}</label>
			<div class="col-lg-9 col-xl-9">
				<div class="input-group input-group-lg input-group-solid">
					<div class="input-group-prepend">
						<span class="input-group-text">
							{{StoreKlikUrl}}
						</span>
					</div>
					<input @change="Url_verif()"  id="tooltip-target-1" ref="shop_url"	type="text"	class="form-control form-control-lg form-control-solid"	:placeholder="$t('SETTINGS.DOMAIN.MSG_STORE_URL_PUBLIC')"	v-bind:value="currentUserPersonalInfo.shop_url"/>
				</div>
				Ou
				<div class="">
					<b>{{StoreKlikUrl}}{{currentUserPersonalInfo.phone_shop}}</b><br><br>
					<b>{{StoreKlikUrl}}{{currentUserPersonalInfo.unique_identity}}</b>
				</div>
			</div>
			<b-tooltip target="tooltip-target-1" triggers="hover">
				<ol>
					<li>
						{{$t('SETTINGS.DOMAIN.MSG_SHOP_TOOLTIP_1')}}
					</li>
					<li>
						{{$t('SETTINGS.DOMAIN.MSG_SHOP_TOOLTIP_2')}}
					</li><li>
						{{$t('SETTINGS.DOMAIN.MSG_SHOP_TOOLTIP_3')}}
					</li>
				</ol>
			</b-tooltip>
			
        </div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.DOMAIN.MSG_SHOP_LINKED_STORE')}}</label>
			<div class="col-lg-9 col-xl-9">
				<p>{{$t('SETTINGS.DOMAIN.MSG_SHOP_CREATE_A_RECORD')}} <b>{{IpDomain}}</b></p>
				<div class="input-group input-group-lg input-group-solid">
					<div class="input-group-prepend">
						<span class="input-group-text">
							https://
						</span>
					</div>
					<input @change="DomainFormat()"  ref="domain_name" type="text" class="form-control form-control-lg form-control-solid" :placeholder="$t('SETTINGS.DOMAIN.MSG_STORE_URL_PRIVET')"	v-bind:value="currentUserPersonalInfo.customer_shop_url"/>
				</div>
			</div>
			<b-tooltip target="tooltip-target-1" triggers="hover">
				<ol>
					<li>
						{{$t('SETTINGS.DOMAIN.MSG_SHOP_TOOLTIP_4')}}
					</li>
					<li>
						{{$t('SETTINGS.DOMAIN.MSG_SHOP_TOOLTIP_5')}}
					</li><li>
						{{$t('SETTINGS.DOMAIN.MSG_SHOP_TOOLTIP_6')}}
					</li>
				</ol>
			</b-tooltip>
			
        </div>
    <!--end::Form-->
  </div>
</template>

<script>

import axios from "axios";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
export default {
  name: "ChangePassword",
  data() {
    return {
			password: "",
			status: "",
			valid: true,
			StoreKlikUrl: null,
			IpDomain: "",
			errorsPassword : [],
			errors : [],
    };
  },
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		this.StoreKlikUrl = process.env.VUE_APP_APP_URL;
		this.IpDomain = process.env.VUE_APP_DOMAIN_IP;
	},
	methods: {
		DomainFormat() {
		
		},
		Url_verif() {
			var shop_url = this.$refs.shop_url.value;
			shop_url = shop_url.replace(/[^a-zA-Z0-9]/g,'-');
			shop_url = shop_url.replace(/^(.com|.edu|.gov|.net|.mil|.org|.nom|.co|.name|.info|.biz)$/i,'');
			shop_url = shop_url.replace(" ",'');
			shop_url = shop_url.replace("--",'');
			shop_url = shop_url.replace("---",'');
			shop_url = shop_url.replace("----",'');
			if(shop_url[shop_url.length-1] == "-" || shop_url[shop_url.length-1] == " "){
				shop_url = shop_url.slice(0, shop_url.length-1);
			}
			if(shop_url[0] == "." ||shop_url[0] == " "){
				shop_url = shop_url.slice(0, 1);
			}
			this.$refs.shop_url.value = shop_url.toLowerCase();
			if(shop_url.length < 5 ){
				this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.DOMAIN.MSG_STORE_NAME_ALREADY_USE"),"error");
			}else{
				axios.post("/klik/customer/shorturl").then((response) => {
					if(response.data.ShortUrl == "false" || response.data.ShortUrl == false){
						this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.DOMAIN.MSG_STORE_NAME_LONG"),"error");
					}
				}).catch((errors) => {    
					console.log(errors);
					localStorage.clear();				
					this.$router.push('/login');
				});
			}
		},
		async isDomain(ADR) {
			var r = RegExp("((http|https)://)(www.)?" + "[a-zA-Z0-9@:%._\\+~#?&//=]"+ "{2,256}\\.[a-z]" + "{2,6}\\b([-a-zA-Z0-9@:%" + "._\\+~#?&//=]*)");
			return r.test("http://"+ADR );
		},
		async SubmitDomain() {
			if(await this.isDomain(this.$refs.domain_name.value)){
				this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.DOMAIN.MSG_INVALID_DOMAIN_FORMAT"),"error");
			}
			var domain_name = this.$refs.domain_name.value
			var shop_url = this.$refs.shop_url.value
			var data = {shop_url:shop_url,domain_name:domain_name};
			axios.post("/klik/customer/update/settings/domain",data).then((response) => {
				console.log(response);
				this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.DOMAIN.MSG_SUCCESS_OPERATION"),"info");
			}).catch((errors) => {    
				console.log(errors)  ;
				localStorage.clear();				
				this.$router.push('/login');
			});
		},
		cancel() {
			this.$refs.new_password.value = "";
			this.$refs.verify_password.value = "";
		},
		/*ErrorsJoin() {
			this.errors = this.errorsPassword.join("<br>");
		},*/
		SwalShow(title,text,icon) {
			Swal.fire({
					title: title,
					text: text,
					icon: icon,
					confirmButtonClass: "btn btn-secondary",
					heightAuto: false
				}); 
		},
    },
	computed: {
		...mapGetters(["currentUserPersonalInfo"]),
		...mapGetters(["currentUserAccountInfo"]),
	}
};
</script>

<style scoped></style>
