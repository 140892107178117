<template>
  <!--begin::Card-->
	<div class="card card-custom">
	<!--begin::Header-->
		<div class="card-header py-3">
			<div class="card-title align-items-start flex-column">
				<h3 class="card-label font-weight-bolder text-dark">
					{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_TITLE_GENERAL_INFORMATIONS')}}
				</h3>
				<span class="text-muted font-weight-bold font-size-sm mt-1"></span>
			</div>
			<div class="card-toolbar">
				<button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
					{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_TO_VALIDATE')}}
				</button>
				<!--<button type="reset" class="btn btn-secondary" @click="cancel()">
					Annuler
				</button>-->
			</div>
		</div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <!--begin::Body-->
      <div class="card-body">
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-9">
            <h5 class="font-weight-bold mb-6">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_STORE_DETAILS')}}</h5>
          </div>
        </div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_LOGO')}}</label>
			<div class="col-lg-9 col-xl-9">
				<div class="image-input image-input-outline" id="kt_profile_avatar">
					<div class="image-input-wrapper" :style="{ backgroundImage: `url(${photo})` }"></div>
					<label class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="change" data-toggle="tooltip" title="" data-original-title="">
						<i class="fa fa-pen icon-sm text-muted"></i>
						<input type="file" name="profile_avatar" id="profile_avatar" accept=".png, .jpg, .jpeg" @change="onFileChange($event)"/>
						<input type="hidden" name="profile_avatar_remove" />
					</label>
					<span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="cancel" data-toggle="tooltip" title="">
						<i class="ki ki-bold-close icon-xs text-muted"></i>
					</span>
					<!--<span class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow" data-action="remove" data-toggle="tooltip" title="Remove avatar" @click="current_photo = null">
						<i class="ki ki-bold-close icon-xs text-muted"></i>
					</span>-->
				</div>
				<span class="form-text text-muted">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_FILE_TYPE')}}</span>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_CATEGORY_BUSINESS')}}</label>
			<div class="col-lg-9 col-xl-9">
				<select ref="category" class="form-control form-control-lg form-control-solid">
				<option v-for="item in categorySelect" :value="item.value" :key="item.value" :selected="currentUserPersonalInfo.category == item.value ? true : false">
					{{ item.text }}
				</option>
				
				</select>
			</div>
        </div>
        <div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_SHOP_NAME')}}</label>
			<div class="col-lg-9 col-xl-9">
				<input ref="shop_name" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.name"/>
			</div>
        </div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_DESCRIPTION')}}</label>
			<div class="col-lg-9 col-xl-9">
				<textarea ref="shop_description" class="form-control form-control-lg form-control-solid" v-bind:value="currentUserPersonalInfo.shop_description">
				</textarea>
			</div>
        </div>
		<!--<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">Boutique adresse publique</label>
			<div class="col-lg-9 col-xl-9">
				<div class="input-group input-group-lg input-group-solid">
					<div class="input-group-prepend">
						<span class="input-group-text">
							{{StoreKlikUrl}}
						</span>
					</div>
					<input @change="Url_verif()"  id="tooltip-target-1" ref="shop_url"	type="text"	class="form-control form-control-lg form-control-solid"	placeholder="Adresse publique"	v-bind:value="currentUserPersonalInfo.shop_url"/>
				</div>
				Ou
				<div class="input-group input-group-lg input-group-solid">
					<div class="input-group-prepend">
						<span class="input-group-text" id="cp1">
							{{StoreKlikUrl}}{{currentUserPersonalInfo.phone_shop}}
						</span>
					</div>
				</div>
				<br>
				<div class="input-group input-group-lg input-group-solid">
					<div class="input-group-prepend">
						<span class="input-group-text" id="cp2">
							{{StoreKlikUrl}}{{currentUserPersonalInfo.unique_identity}}
						</span>
					</div>
				</div>
			</div>
			<b-tooltip target="tooltip-target-1" triggers="hover">
				<ol>
					<li>
						Vous ne pouvez avoir qu’un seul nom de sous domaine pour votre boutique, et il est impossible de choisir un nom de  sous domaine déjà utilisé par quelqu’un d’autre.
					</li>
					<li>
						Les noms ne peuvent contenir que des caractères alphanumériques (A à Z, 0 à 9) et des tiré  (-). Ils ne doivent pas comporter de termes génériques ni d’extensions (.com ou .net).
					</li><li>
						Les noms d’utilisateur doivent comprendre au moins 5 caractères.
					</li>
				</ol>
			</b-tooltip>
			
        </div>-->
		
		
		 <div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_COMPANY_NAME')}}</label>
			<div class="col-lg-9 col-xl-9">
				<input ref="company_name" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.company_name"/>
			</div>
        </div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_TAX_IDENTIFICATION')}}</label>
			<div class="col-lg-9 col-xl-9">
				<input ref="mf_customer" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.mf_customer"/>
			</div>
        </div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_COUNTRY')}}</label>
			<div class="col-lg-9 col-xl-9">
				<select ref="country" class="form-control form-control-lg form-control-solid" type="text">
					<option v-for="item in countrySelect" :value="item.value" :key="item.value" :selected="parseInt(currentUserPersonalInfo.country) == item.value ? true : false">
						{{ item.text }}
						
					</option>
				</select>
			</div>
        </div>

		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_ADRESSE')}}</label>
			<div class="col-lg-9 col-xl-9 mapContainer-wrapper" style="height: 300px;">
				<div id="mapContainer" class="mapContainer" ></div>
				<img src="media/klik/marker.svg" class="noDrag map-marker-img" alt="map marker" style="position: absolute; top: 50%; left: 50%; z-index: 5; width: 30px; height: 45px; z-index: 5; transform: translate(-50%,-100%); ">
			</div>
        </div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right"></label>
			<div class="col-lg-9 col-xl-9">
				<input disabled ref="adresse_customer" id="adresse_customer" class="form-control form-control-lg form-control-solid" type="text"  v-bind:value="currentUserPersonalInfo.adresse_customer"/>
			</div>
        </div>
		<div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_SHOP_NUMBER')}}</label>
          <div class="col-lg-9 col-xl-9">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i> +({{currentUserPersonalInfo.settings.dial}})
                </span>
              </div>
              <input ref="phone_shop" type="text" class="form-control form-control-lg form-control-solid" v-bind:value="currentUserPersonalInfo.phone_shop"/>
            </div>
          </div>
        </div>
		 <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_STORE_EMAIL_ADDRESS')}}</label
          >
          <div class="col-lg-9 col-xl-9">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email_customer"
                type="text"
                class="form-control form-control-lg form-control-solid"
                v-bind:value="currentUserPersonalInfo.email_customer"
              />
            </div>
          </div>
        </div>
		
       
        <div class="row">
          <label class="col-xl-3"></label>
          <div class="col-lg-9 col-xl-9">
            <h5 class="font-weight-bold mt-10 mb-6">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_CONTACT_INFO')}}</h5>
          </div>
        </div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_CONTACT_FIRSTNAME')}}</label>
			<div class="col-lg-9 col-xl-9">
				<input ref="firstname_contact_customer" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.firstname_contact_customer"/>
			</div>
        </div>
        <div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_CONTACT_LASTNAME')}}</label>
			<div class="col-lg-9 col-xl-9">
				<input ref="lastname_contact_customer" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.lastname_contact_customer"/>
			</div>
        </div>

        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_CONTACT_NUMBER')}}</label>
          <div class="col-lg-9 col-xl-9">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-phone"></i>
                </span>
              </div>
              <input ref="phone_contact_customer" type="text" class="form-control form-control-lg form-control-solid" v-bind:value="currentUserPersonalInfo.phone_contact_customer"/>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_EMAIL')}}</label>
          <div class="col-lg-9 col-xl-9">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-at"></i>
                </span>
              </div>
              <input
                ref="email_contact_customer"
                type="text"
                class="form-control form-control-lg form-control-solid"
                v-bind:value="currentUserPersonalInfo.email_contact_customer"
              />
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label text-right"
            >{{$t('SETTINGS.GENERAL_INFORMATIONS.MSG_COMPANY_WEBSITE')}}</label
          >
		   <div class="col-lg-9 col-xl-9">
            <div class="input-group input-group-lg input-group-solid">
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="la la-lightbulb"></i>
                </span>
              </div>
              <input
                ref="company_site"
                type="text"
                class="form-control form-control-lg form-control-solid"
                v-bind:value="currentUserPersonalInfo.company_site"
              />
            </div>
          </div>

        </div>
      </div>
      <!--end::Body-->
    </form>
    <!--end::Form-->
  </div>
</template>
<style>
.mapContainer-wrapper {
  position: relative;
}

#mapContainer {
  width: 100px;
    height:100px;
    min-height: 100%;
    min-width: 100%;
    display: block;
}

.map-marker-img {
    height: 48px!important;
    width: 48px!important;
}
.noDrag {
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
}
</style>
<script>
import { mapGetters } from "vuex";
import L from 'leaflet';
import Swal from "sweetalert2";
//import { LMap/*, LTileLayer, LMarker*/ } from 'vue2-leaflet';

//import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import axios from "axios";
export default {
	name: "PersonalInformation",
	components: {
		//LMap,
		//LTileLayer,
		//LMarker,
		//L
	  },
	data() {
		return {
			default_photo: "media/users/blank.png",
			countrySelect: [],
			categorySelect: [],
			current_photo: null,
			StoreKlikUrl: null,
			//center: [36.8003165,10.1857509],
			Zoom: 16,
			mapDiv:[],
			tileLayer:[],
			marker:[],
			markerPosition:[],
		};
	},
	mounted() {
		this.StoreKlikUrl = process.env.VUE_APP_APP_URL;
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		this.current_photo = this.currentUserPersonalInfo.photo;
		this.getCurrencyCountry();
		this.getCustomerCategory();
		this.markerPosition = [this.currentUserPersonalInfo.adresse_lat,this.currentUserPersonalInfo.adresse_lng];
		this.mapDiv = L.map("mapContainer").setView(this.markerPosition, this.Zoom);
		this.tileLayer = L.tileLayer(
		  'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
		  {
			maxZoom: 18,
			attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
		  }
		).addTo(this.mapDiv);
		setTimeout(()=>{ this.mapDiv.invalidateSize();/*alert("Hello");*/ }, 2000);
		this.mapDiv.on('move', ()=> {
			this.markerPosition = this.mapDiv.getCenter();
			this.markerPosition = [this.markerPosition.lat,this.markerPosition.lng];
			
			console.log(this.markerPosition);
		});
		//Dragend event of map for update marker position
		this.mapDiv.on('dragend', ()=> {
			this.markerPosition = this.mapDiv.getCenter();
			this.markerPosition = [this.markerPosition.lat,this.markerPosition.lng];
			this.GetAdresse(this.markerPosition[0],this.markerPosition[1],this.mapDiv.getZoom());
		});
		this.mapDiv.on('zoomend', ()=> {
			this.markerPosition = this.mapDiv.getCenter();
			this.markerPosition = [this.markerPosition.lat,this.markerPosition.lng];
		});
		this.mapDiv.on('zoomstart', ()=> {
			this.markerPosition = this.mapDiv.getCenter();
			this.markerPosition = [this.markerPosition.lat,this.markerPosition.lng];
		});
	},
	methods: {
		getCurrencyCountry() {
			axios.post("/klik/currency/country").then((response) => {
				let str = [];
				str.push({value:"",text:"-"});
				Object.keys(response.data.CurrencyCountry).forEach(function(i) {
					if(response.data.CurrencyCountry[i].official_name_fr != "")
						str.push({value:response.data.CurrencyCountry[i].id_country_currency,text:response.data.CurrencyCountry[i].official_name_fr+"("+response.data.CurrencyCountry[i].dial+")"});
				});
				this.countrySelect = str;
				console.log(this.countrySelect);
			}).catch((errors) => {    
				console.log(errors)  ;
				/*localStorage.clear();				
				this.$router.push('/login');*/
			});
		},
		getCustomerCategory() {
			axios.post("/klik/customer/category").then((response) => {
				let str = [];
				str.push({value:"",text:"-"});
				Object.keys(response.data.Category).forEach(function(i) {
					str.push({value:response.data.Category[i].id_category_customer,text:response.data.Category[i].name});
				});
				this.categorySelect = str;
				console.log(this.categorySelect);
			}).catch((errors) => {    
				console.log(errors)  ;
				/*localStorage.clear();				
				this.$router.push('/login');*/
			});
		},
		GetAdresse(lat,lng,Zoom) {
			axios.get("https://nominatim.openstreetmap.org/reverse.php?lat="+lat+"&lon="+lng+"&zoom="+Zoom+"&format=jsonv2", { withCredentials: false }).then((response) => {
				//response.data.display_name
				//this.currentUserPersonalInfo.adresse_customer = response.data.display_name;
				document.getElementById("adresse_customer").value =response.data.display_name;
			}).catch((errors) => {    
				console.log(errors)  ;
				/*localStorage.clear();				
				this.$router.push('/login');*/
			});
		},
		submitPicture() {
			
			var ext = ['jpeg',"jpg","png",'JPEG',"JPG","PNG"];

			var formData = new FormData();
			const imagefile = document.querySelector('#profile_avatar');
			for (var x = 0; x < imagefile.files.length; x++) {
				var nameFileExt = imagefile.files[x].name.split(".");
				nameFileExt = nameFileExt[nameFileExt.length-1];
				if(ext.indexOf(nameFileExt) == -1){
					alert(this.$t("SETTINGS.GENERAL_INFORMATIONS.MSG_READ_FILE"));
					return;
				}
				formData.append("file", imagefile.files[x], imagefile.files[x].name);
			}
			//formData.append("id_customer",this.currentUserPersonalInfo.id_customer);
			axios.post("/klik/customer/img",formData, {
																					headers: {
																					  'Content-Type': 'multipart/form-data'
																					}})
			.then((response) => {
				console.log(response);
				
			}).catch((errors) => {
				console.log(errors)  ;
			});
			
		},

		save() {
			const submitButton = this.$refs["kt_save_changes"];
			submitButton.classList.add("spinner", "spinner-light", "spinner-right");
			
			//var photo = this.photo;
			var company_site = this.$refs.company_site.value;
			var shop_name = this.$refs.shop_name.value;
			var shop_description = this.$refs.shop_description.value;
			//var shop_url = this.$refs.shop_url.value;
			var country = this.$refs.country.value;
			var company_name = this.$refs.company_name.value;
			var mf_customer = this.$refs.mf_customer.value;
			var firstname_contact_customer = this.$refs.firstname_contact_customer.value;
			var lastname_contact_customer = this.$refs.lastname_contact_customer.value;
			var category = this.$refs.category.value;
			var phone_contact_customer = this.$refs.phone_contact_customer.value;
			var email_contact_customer = this.$refs.email_contact_customer.value;
			var adresse_customer = this.$refs.adresse_customer.value;
			var phone_shop = this.$refs.phone_shop.value;
			var email_customer = this.$refs.email_customer.value;
			//New
			var adresse_lat = this.markerPosition[0];
			var adresse_lng = this.markerPosition[1];
			
			var data = 	{
							company_site:company_site,
							shop_name:shop_name,
							shop_description:shop_description,
							//shop_url:shop_url,
							country:country,
							company_name:company_name,
							mf_customer:mf_customer,
							firstname_contact_customer:firstname_contact_customer,
							lastname_contact_customer:lastname_contact_customer,
							category:category,
							phone_contact_customer:phone_contact_customer,
							email_contact_customer:email_contact_customer,
							adresse_customer:adresse_customer,
							phone_shop:phone_shop,
							email_customer:email_customer,
							adresse_lat:adresse_lat,
							adresse_lng:adresse_lng
						}
			axios.post("/klik/customer/update/profile",data).then((response) => {
					console.log(response);
					submitButton.classList.remove(
						"spinner",
						"spinner-light",
						"spinner-right"
					);
					this.SwalShow("",'Mise a jour effectie avec succes',"info");
				}).catch((errors) => {    
					console.log(errors)  ;
					/*localStorage.clear();				
					this.$router.push('/login');*/
				});
			
		},
    cancel() {
      this.$refs.name.value = this.currentUserPersonalInfo.name;
      this.$refs.surname.value = this.currentUserPersonalInfo.surname;
      this.$refs.company_name.value = this.currentUserPersonalInfo.company_name;
      this.$refs.phone.value = this.currentUserPersonalInfo.phone;
      this.$refs.email.value = this.currentUserPersonalInfo.email;
      this.$refs.company_site.value = this.currentUserPersonalInfo.company_site;
      this.current_photo = this.currentUserPersonalInfo.photo;
    },
    onFileChange(e) {
      this.submitPicture();
	  const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = event => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
	SwalShow(title,text,icon) {
		Swal.fire({
				title: this.$t("APP_NAME"),
				text: this.$t("SETTINGS.GENERAL_INFORMATIONS.MSG_ERROR_HAS_OCCURRED"),
				icon: icon,
				confirmButtonClass: "btn btn-secondary",
				heightAuto: false
			}); 
	},
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
	...mapGetters(["currentUserAccountInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    }
  }
};
</script>
