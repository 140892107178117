<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
			<div class="card-title align-items-start flex-column">
				<h3 class="card-label font-weight-bolder text-dark">
					{{$t('SETTINGS.ORDER.MSG_TITLE_ORDER')}}
				</h3>
				
			</div>
			<div class="card-toolbar">
				<button type="reset" class="btn btn-success mr-2" @click="save()" ref="kt_save_changes">
					{{$t('SETTINGS.ORDER.MSG_TO_VALIDATE')}}
				</button>
				<!--<button type="reset" class="btn btn-secondary" @click="cancel()">
					Annuler
				</button>-->
			</div>
    </div>
    <!--end::Header-->
    <!--begin::Form-->
    <form class="form">
      <div class="card-body">
        <div class="form-group row">
          <label class="col-xl-3 col-lg-3 col-form-label">{{$t('SETTINGS.ORDER.MSG_CHOOSE_CURRENCY')}}</label>
          <div class="col-lg-9 col-xl-9">
            <select class="form-control form-control-lg form-control-solid" ref="currency" @change="getCurrencyText('1')">
				<template v-for="item in currencyData">
					<option v-bind:key="item.value" :value="item.value" :selected="currentUserPersonalInfo.settings.id_currency == item.value ? true : false">{{ item.text }}</option>
				</template>
            </select>
          </div>
        </div>
		<div class="form-group row">
		  <label class="col-xl-3 col-lg-3 col-form-label">{{$t('SETTINGS.ORDER.MSG_CHOOSE_FORM_CURRENCY')}}</label>
          <div class="col-lg-9 col-xl-9">
            <select class="form-control form-control-lg form-control-solid" ref="currency_format" @change="getCurrencyFormatText('1')">
				<template v-for="item in currencyFormatData">
					<option v-bind:key="item.value" :value="item.value" :selected="currentUserPersonalInfo.settings.currency_format.id_currency_format == item.value ? true : false">{{ item.text }}</option>
				</template>
            </select>
          </div>
		</div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-3 col-form-label text-right">{{$t('SETTINGS.ORDER.MSG_CHOOSE_RIGHT_LEFT')}}</label>
			<div class="col-lg-3 col-xl-3 col-3">
				<span class="switch switch-sm">
					<label>
						<input type="checkbox" ref="currency_right" @change="ShowFormat()" :checked="currentUserPersonalInfo.settings.currency_right" name="email_notification_20"/>
						<span></span>
					</label>
				</span>
			</div>
			<div class="col-lg-6 col-xl-6 col-6">
				<b>{{currencyFormaShow}}</b>
			</div>
        </div>
		<hr>
		<!--<div class="row">
			<label class="col-xl-3"></label>
			<div class="col-lg-9 col-xl-9">
				<h5 class="font-weight-bold mb-6">Prenez des commandes sur:</h5>
			</div>
		</div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">WhatsApp</label>
			<div class="col-lg-9 col-xl-9">
				<span class="switch switch-sm">
					<label>
						<input type="checkbox" ref="InputNotifWhatsApp" @change="ChangeNotifWhatsApp()" :checked="currentUserPersonalInfo.settings.whatsapp" name="email_notification_6"/>
						<span></span>
					</label>
				</span>
				<br>
				<input v-if="NotifWhatsApp" ref="whatsapp_number" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.whatsapp" placeholder="N° WhatsApp"/>
			</div>
        </div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">Messenger</label>
			<div class="col-lg-9 col-xl-9">
				<span class="switch switch-sm">
					<label>
						<input type="checkbox" ref="InputMessenger" @change="ChangeNotifMessenger()" :checked="currentUserPersonalInfo.settings.notif_messenger" name="email_notification_6"/>
						<span></span>
					</label>
				</span>
				<span>
					<select class="form-control form-control-lg form-control-solid" ref="PageFb">
						<template v-for="item in PageData">
							<option v-bind:key="item.id" :value="item.access_token" :selected="currentUserPersonalInfo.settings.id_currency == item.value ? true : false">{{ item.name }}</option>
						</template>
					</select>
				</span>
				<br>
				<br>
				<b v-if="NotifMessenger">
					Pour recevoir les commande sur votre page messenger cliquez 
					<a target="javascript:;" @click="LoginFB()">ici</a> 
					pour associer votre compte 
				</b>
				
				 
			</div>
        </div>
		<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">Sms</label>
			<div class="col-lg-9 col-xl-9">
				<span class="switch switch-sm">
					<label>
						<input type="checkbox" ref="notif_sms" @change="ChangeNotifSms()" :checked="currentUserPersonalInfo.settings.notif_sms" name="email_notification_6"/>
						<span></span>
					</label>
				</span>
				<br>
				<b v-if="NotifSms">
					(+{{currentUserPersonalInfo.settings.dial}}) {{currentUserPersonalInfo.phone_shop}}
				</b>
			</div>
        </div>-->
		<!--<div class="form-group row">
			<label class="col-xl-3 col-lg-3 col-form-label text-right">Messenger</label>
			<div class="col-lg-9 col-xl-9">
				<b>
					pour recevoir les commande sur messenger cliquez 
					<a target="_blank" :href="'http://m.me/106273618053728?ref='+currentUserPersonalInfo.settings.messenger">ici</a> 
					pour associer votre compte
				</b>
				<input ref="messenger_id" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.messenger"/>
			</div>
        </div>
		<hr>-->
		<div class="form-group row align-items-center">
			<label class="col-xl-3 col-lg-3 col-form-label">{{$t('SETTINGS.ORDER.MSG_ACCEPT_ORDERS_FOR')}}</label>
			<div class="col-lg-9 col-xl-9">
				<div class="checkbox-inline">
					<label class="checkbox" style="width: 100%;padding-bottom: 2px;">
						<input type="checkbox" ref="delivery" :checked="currentUserPersonalInfo.settings.delivery" @click="deliveryChecked()"/><span></span>
						{{$t('SETTINGS.ORDER.MSG_HOME_DELIVERY')}}
					</label>
					<label class="checkbox" style="width: 100%;padding-bottom: 2px;">
						<input type="checkbox" ref="self_pickups" :checked="currentUserPersonalInfo.settings.self_pickups"/><span></span> 
						{{$t('SETTINGS.ORDER.MSG_SELF')}}
					</label>
					<label class="checkbox" style="width:100%" v-if="currentUserPersonalInfo.settings.in_resto_show">
						<input type="checkbox" ref="in_resto" :checked="currentUserPersonalInfo.settings.in_resto"/><span></span> 
						{{$t('SETTINGS.ORDER.MSG_AT_TABLE')}}
					</label>
				</div>
			</div>
        </div>
		<div class="form-group row align-items-center" v-if="InputDeliveryChecked">
			<label class="col-xl-3 col-lg-3 col-form-label">{{$t('SETTINGS.ORDER.MSG_DELIVERING_TO')}}</label>
			<div class="col-lg-9 col-xl-9">
				<textarea ref="delivering_to" class="form-control form-control-lg form-control-solid" v-bind:value="currentUserPersonalInfo.settings.delivering_to" rows="5" :placeholder="$t('MSG_DELIVERING_TO_PLACEHOLDER')">
				</textarea>
			</div>
        </div>
        <!--<div class="form-group row align-items-center">
			<label class="col-xl-3 col-lg-3 col-form-label">Recevoir la commande dans</label>
			<div class="col-lg-9 col-xl-9">
				<div class="checkbox-inline">
					<label class="checkbox" style="width: 100%;padding-bottom: 2px;">
						<input type="checkbox" ref="notif_whatsapp" :checked="currentUserPersonalInfo.settings.notif_whatsapp"/><span></span> 
						WhatsApp
					</label>
					<label class="checkbox" style="width: 100%;padding-bottom: 2px;">
						<input type="checkbox" ref="notif_sms" :checked="currentUserPersonalInfo.settings.notif_sms"/><span></span> 
						SMS
					</label>
					<label class="checkbox" style="width: 100%;padding-bottom: 2px;">
						<input type="checkbox" ref="notif_email" :checked="currentUserPersonalInfo.settings.notif_email"/><span></span> 
						Email
					</label>
					<label class="checkbox" style="width:100%">
						<input type="checkbox" ref="notif_messenger" :checked="currentUserPersonalInfo.settings.notif_email"/><span></span> 
						Messenger
					</label>
				</div>
          </div>
        </div>-->
        <!--begin::Form Group-->
        <div class="separator separator-dashed my-5"></div>
        <!--begin::Form Group-->
			<div class="row">
				<label class="col-xl-3"></label>
				<div class="col-lg-9 col-xl-9">
					<h5 class="font-weight-bold mb-6">{{$t('SETTINGS.ORDER.MSG_COMMAND_SETTINGS')}}</h5>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.ORDER.MSG_MINIMUM_ORDER_DELIVERY')}}</label>
				<div class="col-lg-9 col-xl-9">
					<div class="input-group input-group-lg input-group-solid">
						<input ref="minimum_order_for_deliver" class="form-control form-control-lg form-control-solid" type="text"  v-bind:value="currentUserPersonalInfo.settings.minimum_order_for_deliver"/>
						<div class="input-group-appand">
							<span class="input-group-text">
								<i :class='currencyText.class'>{{currencyText.iso}}</i>
							</span>
						</div>
					
					</div>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.ORDER.MSG_MINIMUM_ORDER_FREE_DELIVERY')}}</label>
				<div class="col-lg-9 col-xl-9">
					<div class="input-group input-group-lg input-group-solid">
						<input ref="minimum_order_for_free_delivery" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.minimum_order_for_free_delivery"/>
						<div class="input-group-appand">
							<span class="input-group-text">
								<i :class='currencyText.class'>{{currencyText.iso}}</i>
							</span>
						</div>
					
					</div>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.ORDER.MSG_SHIPPING_COST')}}</label>
				<div class="col-lg-9 col-xl-9">
					<div class="input-group input-group-lg input-group-solid">
						<input ref="delivery_fees" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.delivery_fees"/>
						<div class="input-group-appand">
							<span class="input-group-text">
								<i :class='currencyText.class'>{{currencyText.iso}}</i>
							</span>
						</div>
					
					</div>
				</div>
			</div>
			<div class="form-group row">
				<label class="col-xl-3 col-lg-3 col-form-label text-right">{{$t('SETTINGS.ORDER.MSG_SHIPPING_TAX')}}</label>
				<div class="col-lg-9 col-xl-9">
					<div class="input-group input-group-lg input-group-solid">
						<input ref="tax" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.tax"/>
						<div class="input-group-appand">
							<span class="input-group-text">
								%
							</span>
						</div>
					
					</div>
				</div>
			</div>
			
		</div>
    </form>
    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>
<style>
    .ManeyIco{
		font-size: 15px!important;
		color: #000!important;
		font-style: inherit!important;
	}
</style>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
//import { UPDATE_ACCOUNT_INFO } from "@/core/services/store/profile.module";
import axios from "axios";
//import facebookLogin from 'facebook-login-vuejs';

export default {
	name: "AccountInformation",
	components: {
       // facebookLoginUrl
    },
	data() {
		return {
			currencyFormaShow: "",
			currencyData: [],
			PageData: [],
			currencyFormatData: [],
			currencyText: {},
			currencyFormatParams: {},
			InputDeliveryChecked: false,
			NotifWhatsApp: false,
			NotifMessenger: false,
			NotifSms: false,
			
		};
	},
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		this.getCurrencyCountry();
		this.getCurrencyFormat();
		this.InputDeliveryChecked = this.currentUserPersonalInfo.settings.delivery;
		this.NotifWhatsApp = this.currentUserPersonalInfo.settings.notif_whatsapp;
		this.NotifMessenger = this.currentUserPersonalInfo.settings.notif_messenger;
		this.NotifSms = this.currentUserPersonalInfo.settings.notif_sms;
		/*window.FB.init({
			appId      : '824472061735814',
			cookie     : true,  // enable cookies to allow the server to access 
			xfbml      : true,  // parse social plugins on this page
			version    : 'v10.0' // use graph api version 2.8
		});*/
		//this.LoginFB();
	},
	created: function() {
	},
	methods: {
		ShowFormat() {
			//this.currencyFormatParams
			this.currencyFormaShow = this.numberFormatShow("1234567,89",this.currencyFormatParams.params.decimals,this.currencyFormatParams.params.dec_point,this.currencyFormatParams.params.thousands_sep,this.currencyText.iso,this.$refs.currency_right.checked)
			//numberFormatShow  currency_format
		},
		deliveryChecked() {
			this.InputDeliveryChecked = this.$refs.delivery.checked;
		},
		/*ChangeNotifWhatsApp() {
			this.NotifWhatsApp = this.$refs.InputNotifWhatsApp.checked;
		},
		ChangeNotifMessenger() {
			this.NotifMessenger = this.$refs.InputMessenger.checked;
		},
		ChangeNotifSms() {
			this.NotifSms = this.$refs.notif_sms.checked;
		},*/
		getCurrencyCountry() {
			axios.post("/klik/currency/country").then((response) => {
				let str = [];
				let strDeplicate = [];
				str.push({value:"",text:"-",iso:"",class:"la la-money-bill"});
				Object.keys(response.data.CurrencyCountry).forEach((i)=>{
					if(response.data.CurrencyCountry[i].official_name_fr != "" && strDeplicate.indexOf(response.data.CurrencyCountry[i].iso4217_currency_alphabetic_code) == -1){
						str.push({value:response.data.CurrencyCountry[i].id_country_currency,text:response.data.CurrencyCountry[i].iso4217_currency_name+"("+ response.data.CurrencyCountry[i].iso4217_currency_alphabetic_code+")",iso:response.data.CurrencyCountry[i].iso4217_currency_alphabetic_code,class:"ManeyIco"});
						strDeplicate.push(response.data.CurrencyCountry[i].iso4217_currency_alphabetic_code);
					}
				});
				strDeplicate = [];
				this.currencyData = str;
				this.getCurrencyText('0');
			}).catch((errors) => {    
				console.log(errors)  ;
				/*localStorage.clear();				
				this.$router.push('/login');*/
			});
		},
		getCurrencyFormat() {
			axios.post("/klik/customer/currency/format/get").then((response) => {
				let str = [];
				Object.keys(response.data.CurrencyFormat).forEach(function(i) {
					if(response.data.CurrencyFormat[i].official_name_fr != "")
						str.push({value:response.data.CurrencyFormat[i].id_currency_format,text:response.data.CurrencyFormat[i].format_exemple,params:response.data.CurrencyFormat[i]});
				});
				this.currencyFormatData = str;
				this.getCurrencyFormatText('0');
			}).catch((errors) => {    
				console.log(errors)  ;
				/*localStorage.clear();				
				this.$router.push('/login');*/
			});
		},
		getCurrencyText(ind) {
			var currency = this.$refs.currency.value;
			if(ind == 0){
				currency = this.currentUserPersonalInfo.settings.id_currency
			}
			for(var i in this.currencyData){
				if(this.currencyData[i].value == currency){
					this.currencyText = {iso:this.currencyData[i].iso,class:this.currencyData[i].class};
					this.ShowFormat();
					return true;
				}
			}
			this.ShowFormat();
		},
		getCurrencyFormatText(ind) {
			
			var Format = this.$refs.currency_format.value;
			if(ind == 0){
				Format = this.currentUserPersonalInfo.settings.currency_format.id_currency_format;
			}
			for(var i in this.currencyFormatData){
				if(this.currencyFormatData[i].value == Format){
					this.currencyFormatParams = this.currencyFormatData[i];
					this.ShowFormat();
					return true;
				}
			}
		},
		save() {
			const submitButton = this.$refs["kt_save_changes"];
			submitButton.classList.add("spinner", "spinner-light", "spinner-right");
			var currency = this.$refs.currency.value;
			
			//var messenger_id = this.$refs.messenger_id.value;
			var delivery = this.$refs.delivery.checked;
			var self_pickups = this.$refs.self_pickups.checked;
			var in_resto = false;
			if(this.currentUserPersonalInfo.settings.in_resto_show){
				in_resto = this.$refs.in_resto.checked;
			}
			//var notif_whatsapp = this.$refs.InputNotifWhatsApp.checked;
			//var notif_sms = this.$refs.notif_sms.checked;
			//var notif_email = false;//this.$refs.notif_email.checked;
			//var notif_messenger = this.$refs.InputMessenger.checked;
			var minimum_order_for_deliver = this.$refs.minimum_order_for_deliver.value;
			var minimum_order_for_free_delivery = this.$refs.minimum_order_for_free_delivery.value;
			var delivery_fees = this.$refs.delivery_fees.value;
			var tax = this.$refs.tax.value;
			var delivering_to = this.currentUserPersonalInfo.settings.delivering_to;
			var whatsapp_number = this.currentUserPersonalInfo.settings.whatsapp;
			if(this.$refs.whatsapp_number){
				whatsapp_number = this.$refs.whatsapp_number.value;
			}
			if(this.$refs.delivering_to){
				delivering_to = this.$refs.delivering_to.value;
			}
			/*if(notif_whatsapp == true){
				if(whatsapp_number == ""){
					this.SwalShow("",'Un numéro WhatsApp est obligatoire pour utiliser Notification sur WhatsApp',"error");
					return 
				}
			}*/
			/*if(notif_sms == true){
				if(this.currentUserPersonalInfo.phone_shop == ""){
					this.SwalShow("",'Un numéro de téléphone est obligatoire',"error");
					return 
				}
			}*/
			
			//this.$refs.currency_format.value
			
			var data = 	{
							currency:currency,
							whatsapp_number:whatsapp_number,
							//messenger_id:messenger_id,
							delivery:delivery,
							self_pickups:self_pickups,
							in_resto:in_resto,
							//notif_whatsapp:notif_whatsapp,
							//notif_sms:notif_sms,
							//notif_email:notif_email,
							//notif_messenger:notif_messenger,
							minimum_order_for_deliver:minimum_order_for_deliver,
							minimum_order_for_free_delivery:minimum_order_for_free_delivery,
							delivery_fees:delivery_fees,
							tax:tax,
							delivering_to:delivering_to,
							currency_format:this.$refs.currency_format.value
						}
			axios.post("/klik/customer/update/settings",data).then((response) => {
					console.log(response);
					this.UpdateSession;
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.ORDER.MSG_SUCCESS_OPERATION"),"info")
					submitButton.classList.remove(
						"spinner",
						"spinner-light",
						"spinner-right"
					);
				}).catch((errors) => {    
					console.log(errors)  ;
					localStorage.clear();				
					this.$router.push('/login');
				});
		},
		cancel() {
			this.UpdateSession;
			this.getCurrencyCountry();
			this.getCurrencyFormat();
			this.InputDeliveryChecked = this.currentUserPersonalInfo.settings.delivery;
			this.NotifWhatsApp = this.currentUserPersonalInfo.settings.notif_whatsapp;
			this.NotifMessenger = this.currentUserPersonalInfo.settings.notif_messenger;
			this.NotifSms = this.currentUserPersonalInfo.settings.notif_sms;
		},
		SwalShow(title,text,icon) {
			Swal.fire({
						title: title,
						text: text,
						icon: icon,
						confirmButtonClass: "btn btn-secondary",
						heightAuto: false
					}); 
		},
	},
	computed: {
		...mapGetters(["currentUserAccountInfo"]),
		...mapGetters(["currentUserPersonalInfo"]),
		...mapGetters(["UpdateSession"]),
		...mapGetters(["numberFormatShow"]),
	}
};
</script>