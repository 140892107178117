<template>
  <div class="row">
    <div class="col-xl-4 col-12 mb-5" id="kt_profile_aside">
      <div class="card card-custom card-stretch">
        <div class="card-body pt-4">
          <div class="d-flex align-items-center">
            <div
              class="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center"
            >
              <div
                class="symbol-label"
                :style="{
                  backgroundImage: `url(${currentUserPersonalInfo.photo})`
                }"
              ></div>
              <i class="symbol-badge bg-success"></i>
            </div>
            <div>
              <a
                class="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary"
                >{{ getFullName }}</a
              >
            </div>
          </div> 
          <!--end::User-->
          <!--begin::Contact-->
          <div class="py-9">
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">{{$t('SETTINGS.MSG_EMAIL')}}</span>
              <a class="text-muted text-hover-primary">{{
                currentUserPersonalInfo.email_customer
              }}</a>
            </div>
            <div class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-weight-bold mr-2">{{$t('SETTINGS.MSG_PHONE_NUMBER')}}</span>
              <span class="text-muted">(+{{currentUserPersonalInfo.settings.dial}}) {{currentUserPersonalInfo.phone_shop}}</span>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <span class="font-weight-bold mr-2">{{$t('SETTINGS.MSG_CUSTOMER_NAME')}}</span>
              <span class="text-muted">{{
                currentUserPersonalInfo.company_name
              }}</span>
            </div>
          </div>
          <!--end::Contact-->
          <!--begin::Nav-->
          <div
            class="navi navi-bold navi-hover navi-active navi-link-rounded"
            role="tablist"
          >
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4 active"
                @click="setActiveTab"
                data-tab="0"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/General/User.svg" />
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('SETTINGS.MSG_GENERAL_INFORMATIONS')}}</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                data-tab="1"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Design/Layers.svg" />
					
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('SETTINGS.MSG_COMMAND_PARAMETERS')}}</span>
              </a>
            </div>
			<div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                data-tab="5"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg src="media/svg/icons/Design/Layers.svg" />
					
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('SETTINGS.MSG_SALES_CHANNEL')}}</span>
              </a>
            </div>
			<div class="navi-item mb-2">
              <a
                class="navi-link py-4"
				 @click="setActiveTab"
                data-tab="3"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg
                      src="media/svg/icons/Layout/Layout-top-panel-6.svg"
                    />
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('SETTINGS.MSG_PAYMENT')}}</span>
              </a>
            </div>
            <div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                data-tab="2"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg
                      src="media/svg/icons/Communication/Shield-user.svg"
                    />
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('SETTINGS.MSG_SECURITY_CONNECTION')}}</span>
                <!--<span class="navi-label">
                  <span
                    class="label label-light-danger label-rounded font-weight-bold"
                    >5</span
                  >
                </span>-->
              </a>
            </div>
			<div class="navi-item mb-2">
              <a
                class="navi-link py-4"
                @click="setActiveTab"
                data-tab="4"
                data-toggle="tab"
                role="tab"
                aria-selected="false"
              >
                <span class="navi-icon mr-2">
                  <span class="svg-icon">
                    <inline-svg
                      src="media/svg/icons/Communication/Flag.svg"
                    />
                  </span>
                </span>
                <span class="navi-text font-size-lg">{{$t('SETTINGS.MSG_CUSTOM_DOMAIN')}}</span>
                <!--<span class="navi-label">
                  <span
                    class="label label-light-danger label-rounded font-weight-bold"
                    >5</span
                  >
                </span>-->
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--begin::Content-->
    <div class="col-xl-8 col-12  mb-5">
      <b-tabs class="hide-tabs" v-model="tabIndex">
        <!--<b-tab active>
          <KTProfileOverview></KTProfileOverview>
        </b-tab>-->

        <b-tab>
          <KTPersonalInformation></KTPersonalInformation>
        </b-tab>

        <b-tab>
          <KTAccountInformation></KTAccountInformation>
        </b-tab>

        <b-tab>
          <KTChangePassword></KTChangePassword>
        </b-tab>

        <b-tab>
          <KTEmailSettings></KTEmailSettings>
        </b-tab>
		<b-tab>
          <KTDomainSettings></KTDomainSettings>
        </b-tab>
		<b-tab>
          <ChannelSettings></ChannelSettings>
        </b-tab>
      </b-tabs>
    </div>
    <!--end::Content-->
  </div>
</template>
<style>
.nav-tabs{
	display: none;
}
</style>
<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
//import KTDropdown2 from "@/view/pages/klik/profile-composant/Dropdown2";
//import KTProfileOverview from "@/view/pages/klik/profile-composant/ProfileOverview";
import KTPersonalInformation from "@/view/pages/klik/profile-composant/PersonalInformation";
import KTAccountInformation from "@/view/pages/klik/profile-composant/AccountInformation";
import KTChangePassword from "@/view/pages/klik/profile-composant/ChangePassword";
import KTEmailSettings from "@/view/pages/klik/profile-composant/EmailSettings";
import KTDomainSettings from "@/view/pages/klik/profile-composant/DomainSettings";
import ChannelSettings from "@/view/pages/klik/profile-composant/Channel";

export default {
  name: "custom-page",
  components: {
    //KTDropdown2,
    //KTProfileOverview,
    KTPersonalInformation,
    KTAccountInformation,
    KTChangePassword,
    KTEmailSettings,
	KTDomainSettings,
	ChannelSettings
  },
  data() {
    return {
      tabIndex: 0
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Profile" }]);
  },
  methods: {
    /**
     * Set current active on click
     * @param event
     */
    setActiveTab(event) {
      let target = event.target;
      if (!event.target.classList.contains("navi-link")) {
        target = event.target.closest(".navi-link");
      }

      const tab = target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".navi-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set clicked tab index to bootstrap tab
      this.tabIndex = parseInt(target.getAttribute("data-tab"));

      // set current active tab
      target.classList.add("active");
    }
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    }
  }
};
</script>
