<template>
  <!--begin::Card-->
  <div class="card card-custom">
    <!--begin::Header-->
    <div class="card-header py-3">
		<div class="card-title align-items-start flex-column">
			<h3 class="card-label font-weight-bolder text-dark">
				{{$t('SETTINGS.SALES_CHANNEL.MSG_TITLE_SALES_CHANNEL')}}
			</h3>
			
		</div>
    </div>
	<div class="row">	
		<div class="row" style="margin: 1px 21px 0 21px;">	
			<div class="col-xl-3 col-lg-3">
				<v-card class="mx-auto"  color="#128C7E"  dark  max-width="400">
				  <v-card-title>
					<v-icon large left >
					  mdi-whatsapp 
					</v-icon>
					<span class="title font-weight-light">WhatsApp</span>
				  </v-card-title>

				  <v-card-text class="headline font-weight-bold">
					{{$t('SETTINGS.SALES_CHANNEL.MSG_DES_WHATSAPP')}}
				  </v-card-text>

					<v-card-actions>
						<v-list-item class="grow">
						<v-list-item-content>
							<button type="reset" @click="OpenSettingsP('wh')" class="btn btn-success-doken mr-2">
								{{$t('SETTINGS.SALES_CHANNEL.MSG_START')}}
							</button>
						</v-list-item-content>
						</v-list-item>
					</v-card-actions>
				</v-card>
			</div>
			<div class="col-xl-3 col-lg-3">
				<v-card class="mx-auto"  color="#4267B2"  dark  max-width="400">
				  <v-card-title>
					<v-icon large left >
					  mdi-facebook
					</v-icon>
					<span class="title font-weight-light">Facebook</span>
				  </v-card-title>

				  <v-card-text class="headline font-weight-bold" v-html="$t('SETTINGS.SALES_CHANNEL.MSG_DES_FACEBOOK')">
						Connectez votre boutique à votre page Facebook<br>
						(autorisations d'administrateur requises)
				  </v-card-text>

					<v-card-actions>
						<v-list-item class="grow">
						<v-list-item-content>
							<button type="reset" @click="OpenSettingsP('fb')" class="btn btn-success-doken mr-2">
								{{$t('SETTINGS.SALES_CHANNEL.MSG_START')}}
							</button>
						</v-list-item-content>
						</v-list-item>
					</v-card-actions>
				</v-card>
			</div>
			<div class="col-xl-3 col-lg-3">
				<v-card class="mx-auto"  color="#0bb7af"  dark  max-width="400">
				  <v-card-title>
					<v-icon large left >
					  mdi-phone
					</v-icon>
					<span class="title font-weight-light">SMS</span>
				  </v-card-title>

				  <v-card-text class="headline font-weight-bold">
					{{$t('SETTINGS.SALES_CHANNEL.MSG_DES_SMS')}}
				  </v-card-text>

					<v-card-actions>
						<v-list-item class="grow">
						   <v-list-item-content>
							<button type="reset" @click="OpenSettingsP('sms')" class="btn btn-success-doken mr-2">
								{{$t('SETTINGS.SALES_CHANNEL.MSG_START')}}
							</button>
						  </v-list-item-content>
						</v-list-item>
					</v-card-actions>
				</v-card>
			</div>
			<div class="col-xl-3 col-lg-3">
				<v-card class="mx-auto"  color="#DB4437"  dark  max-width="400">
				  <v-card-title>
					<v-icon large left >
					  mdi-email
					</v-icon>
					<span class="title font-weight-light">SMTP</span>
				  </v-card-title>

				  <v-card-text class="headline font-weight-bold">
					{{$t('SETTINGS.SALES_CHANNEL.MSG_DES_SMTP')}}
				  </v-card-text>

				  <v-card-actions>
					<v-list-item class="grow">
					   <v-list-item-content>
						<button type="reset" @click="OpenSettingsP('smtp')" class="btn btn-success-doken mr-2">
							{{$t('SETTINGS.SALES_CHANNEL.MSG_START')}}
						</button>
					  </v-list-item-content>
					</v-list-item>
				  </v-card-actions>
				</v-card>
			</div>
		</div>
	</div>
	
	<div data-app>
		<v-layout row justify-center>
			<v-dialog v-model="OpenSettings" fullscreen hide-overlay transition="dialog-bottom-transition">
				<v-card >
					<v-toolbar flat dark color="primary">
						<v-btn icon dark @click="OpenSettings = false">
							<i class="la la-close"></i>
						</v-btn>
						<v-toolbar-title>{{$t('SETTINGS.SALES_CHANNEL.MSG_TITLE_SALES_CHANNEL')}}</v-toolbar-title>
						<v-spacer></v-spacer>
						<v-toolbar-items>
							<v-btn success  @click="UpdateChanel();">{{$t('SETTINGS.SALES_CHANNEL.MSG_TO_VALIDATE')}}</v-btn>
						</v-toolbar-items>
					</v-toolbar>
					<v-list three-line subheader>
						<b-card class="main-card mb-3" v-if="OpenSettingsFacebook">
								<h1>{{$t('SETTINGS.SALES_CHANNEL.MSG_TITLE_POPUP_FB')}}</h1>
								<div class="row">	
									<div class="col-lg-8 col-xl-8">
									</div>
									<div class="col-lg-2 col-xl-2">
										<button @click="LoginFB()" type="reset" class="btn btn-success mr-2 pull-right">
											{{$t('SETTINGS.SALES_CHANNEL.MSG_REFRESH')}}
										</button>
									</div>
									<div class="col-lg-2 col-xl-2">
										<button onclick="window.open('https://www.facebook.com/pages/creation/', '_blank');" type="reset" class="btn btn-info mr-2 pull-right">
											{{$t('SETTINGS.SALES_CHANNEL.MSG_CREATE_PAGE')}}
										</button>
									</div>
								</div>
								<div class="row">	
									<div class="col-lg-12 col-xl-12">
										<select class="form-control form-control-lg form-control-solid" ref="PageFb">
											<template v-for="item in PageData">
												<option v-bind:key="item.id_customer_bot_page" :value="item.id_customer_bot_page" :selected="currentUserPersonalInfo.id_customer_bot_page == item.id_customer_bot_page ? true : false">{{ item.page_name }}</option>
											</template>
										</select>
									</div>
								</div>
						</b-card>
						<b-card class="main-card mb-3" v-if="OpenSettingsWhatsapp">
								<h1>{{$t('SETTINGS.SALES_CHANNEL.MSG_TITLE_POPUP_WA')}}</h1>
								<div class="col-lg-12 col-xl-12">
									<span class="switch switch-sm">
										<label>
											<input type="checkbox" ref="InputNotifWhatsApp" @change="ChangeNotifWhatsApp()" :checked="currentUserPersonalInfo.settings.whatsapp" name="email_notification_6"/>
											<span></span>
										</label>
									</span>
									<br>
									<input v-if="NotifWhatsApp" ref="whatsapp_number" class="form-control form-control-lg form-control-solid" type="text" v-bind:value="currentUserPersonalInfo.settings.whatsapp" :placeholder="$t('MSG_TITLE_NUMBER_WA')"/>
								</div>
						</b-card>
						<b-card class="main-card mb-3" v-if="OpenSettingsSms">
								<div class="col-lg-12 col-xl-12">
									<b>{{$t('SETTINGS.SALES_CHANNEL.MSG_TITLE_POPUP_SMS')}}</b>
									<span class="switch switch-sm">
										<label>
											<input type="checkbox" ref="notif_sms" @change="ChangeNotifSms()" :checked="currentUserPersonalInfo.settings.notif_sms" name="email_notification_6"/>
											<span></span>
										</label>
									</span>
									<br>
									<b v-if="NotifSms">
										(+{{currentUserPersonalInfo.settings.dial}}) {{currentUserPersonalInfo.phone_shop}}
									</b>
								</div>
						</b-card>
						
					</v-list>
				</v-card>
			</v-dialog>
		</v-layout>
	</div>
    <!--end::Header-->
    <!--begin::Form-->

    <!--end::Form-->
  </div>
  <!--end::Card-->
</template>
<style>
    .ManeyIco{
		font-size: 15px!important;
		color: #000!important;
		font-style: inherit!important;
	}
	.btn-success-doken{
		border: 1px solid #fff;
		color:#fff;
	}
	.btn.btn-success-doken:hover:not(.btn-text):not(:disabled):not(.disabled), .btn.btn-success-doken:focus:not(.btn-text), .btn.btn-success-doken.focus:not(.btn-text) {
		color: #000;
		//font-size: 15px;
		background-color:#fff;
	}
</style>

<script>
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
//import { UPDATE_ACCOUNT_INFO } from "@/core/services/store/profile.module";
import axios from "axios";
//import facebookLogin from 'facebook-login-vuejs';

export default {
	name: "AccountInformation",
	components: {
       // facebookLoginUrl
    },
	data() {
		return {
			OpenSettings: false,
			OpenSettingsFacebook: true,
			OpenSettingsWhatsapp: false,
			OpenSettingsSms: false,
			OpenSettingsSmtp: false,
			PageData: "",
			NotifWhatsApp: false,
			NotifSms: false, 
			
			
			/*currencyFormaShow: "",
			currencyData: [],
			PageData: [],
			currencyFormatData: [],
			currencyText: {},
			currencyFormatParams: {},
			InputDeliveryChecked: false,
			NotifWhatsApp: false,
			NotifMessenger: false,
			NotifSms: false,*/
			
		};
	},
	mounted() {
		axios.defaults.headers["Content-Type"] =  "application/json";
		axios.defaults.withCredentials = true;
		axios.defaults.baseURL = process.env.VUE_APP_APP_API;
		
		this.PageData = this.currentUserPersonalInfo.settings.bot;
		this.SelectedBotFacebook = this.currentUserPersonalInfo.id_customer_bot_page;
		this.NotifWhatsApp = this.currentUserPersonalInfo.settings.notif_whatsapp;
		this.NotifSms = this.currentUserPersonalInfo.settings.notif_sms;
		window.FB.init({
			appId      : '824472061735814',
			cookie     : true,  // enable cookies to allow the server to access 
			xfbml      : true,  // parse social plugins on this page
			version    : 'v10.0' // use graph api version 2.8
		});
	},
	created: function() {
		//this.LoginFB();
	},
	methods: {
		OpenSettingsP(type) {
			this.OpenSettings = false;
			this.OpenSettingsFacebook = false;
			this.OpenSettingsWhatsapp = false;
			this.OpenSettingsSms = false;
			this.OpenSettingsSmtp = false;
			if(type == "fb"){
				this.LoginFB();
				//this.OpenSettings = true;
				//this.OpenSettingsFacebook = true;
			}else if(type == "wh"){
				this.OpenSettings = true;
				this.OpenSettingsWhatsapp = true;
			}else if(type == "sms"){
				this.OpenSettings = true;
				this.OpenSettingsSms = true;
			}else if(type == "smtp"){
				this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.SALES_CHANNEL.MSG_FEATURE_UNDER_DEVELOPMENT"),"info");
			}
		},
		LoginFB() {
			window.FB.login((response)=> {
				console.log(response.status);
				if (response.status === 'connected') {
					//response = {};
					//response.authResponse = {};
					var userAccessToken = response.authResponse.accessToken;
					var userID = response.authResponse.userID;
					this.UpdateFbUserData(userID,userAccessToken);
					this.getUserData(userID,userAccessToken);
					
				} else { 
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.SALES_CHANNEL.MSG_ERROR_CNX_FB"),"error");
				}
			  
			}, {scope: 'public_profile,email,pages_manage_metadata,pages_messaging,pages_show_list'});
		},
		getUserData(userID,userAccessToken) {
			window.FB.api(userID+'/accounts', 'GET', {"fields":"name,access_token","access_token":userAccessToken}, (response)=> {
				this.PageData = response;
				this.SendAllPagesFb(response);
				this.OpenSettings = true;
				this.OpenSettingsFacebook = true;
				console.log(response);
			});
		},
		SendSubscribedApps(page_id,userAccessToken) {
			window.FB.api(
				"/"+page_id+"/subscribed_apps",
				"POST",
				{
					"subscribed_fields":  ['messages','messaging_referrals','messaging_optins','messaging_postbacks'],
					"access_token":userAccessToken,
				},
				function (response) {
				  if (response && !response.error) {
					/* handle the result */
				  }
				}
			);
		},
		SendAllPagesFb(page) {
			var data = {page:page}
			axios.post("/klik/customer/all/pages/add",data).then(() => {
				this.UpdateSession;
				 setTimeout(() => {
					this.GetPageFbFromDb();
				}, 2000);
				
			}).catch((errors) => {    
				console.log(errors);
			});
		},
		UpdateFbUserData(userID,userAccessToken) {
			var data = {userID:userID,accessToken:userAccessToken}
			axios.post("/klik/customer/bot/facebook",data).then((response) => {
				console.log(response);
			}).catch((errors) => {    
				console.log(errors);
			});
		},
		GetPageFbFromDb() {
			axios.post("/klik/customer/get/bot/facebook").then((response) => {
				this.PageData = response.data.facebook
			}).catch((errors) => {    
				console.log(errors);
			});
		},
		UpdateChanel() {
			var data = {};
			if(this.OpenSettingsFacebook == true){
				for(var i in this.PageData){
					if(this.PageData[i].id_customer_bot_page == this.$refs.PageFb.value){
						this.SendSubscribedApps(this.PageData[i].page_id,this.PageData[i].access_token);
						console.log("SendSubscribedApps");
					}
				}
				data = {type:"facebook",value:this.$refs.PageFb.value}
			}else if(this.OpenSettingsWhatsapp == true){
				var whatsapp_number = this.currentUserPersonalInfo.settings.whatsapp;
				if(this.$refs.whatsapp_number){
					whatsapp_number = this.$refs.whatsapp_number.value;
				}
				data = {type:"whatsapp",namber:whatsapp_number,notif:this.NotifWhatsApp};
				
			}else if(this.OpenSettingsSms == true){
				data = {type:"sms",notif:this.NotifSms};
			}
			axios.post("/klik/update/notif",data).then(() => {
				this.OpenSettings = false;
					this.UpdateSession;
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.SALES_CHANNEL.MSG_SUCCESS_OPERATION"),"info")
				}).catch((errors) => { 
					console.log(errors)  ;
					localStorage.clear();				
					this.$router.push('/login');
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.SALES_CHANNEL.MSG_SUCCESS_OPERATION"),"info")
				});
		},

		ChangeNotifWhatsApp() {
			this.NotifWhatsApp = this.$refs.InputNotifWhatsApp.checked;
		},
		ChangeNotifMessenger() {
			this.NotifMessenger = this.$refs.InputMessenger.checked;
		},
		ChangeNotifSms() {
			this.NotifSms = this.$refs.notif_sms.checked;
		},
		getCurrencyCountry() {
			axios.post("/klik/currency/country").then((response) => {
				let str = [];
				str.push({value:"",text:"-",iso:"",class:"la la-money-bill"});
				Object.keys(response.data.CurrencyCountry).forEach(function(i) {
					if(response.data.CurrencyCountry[i].official_name_fr != "")
						str.push({value:response.data.CurrencyCountry[i].id_country_currency,text:response.data.CurrencyCountry[i].iso4217_currency_name+"("+ response.data.CurrencyCountry[i].iso4217_currency_alphabetic_code+")",iso:response.data.CurrencyCountry[i].iso4217_currency_alphabetic_code,class:"ManeyIco"});
				});
				this.currencyData = str;
				this.getCurrencyText('0');
			}).catch((errors) => {    
				console.log(errors)  ;
				/*localStorage.clear();				
				this.$router.push('/login');*/
			});
		},
		getCurrencyFormat() {
			axios.post("/klik/customer/currency/format/get").then((response) => {
				let str = [];
				Object.keys(response.data.CurrencyFormat).forEach(function(i) {
					if(response.data.CurrencyFormat[i].official_name_fr != "")
						str.push({value:response.data.CurrencyFormat[i].id_currency_format,text:response.data.CurrencyFormat[i].format_exemple,params:response.data.CurrencyFormat[i]});
				});
				this.currencyFormatData = str;
				this.getCurrencyFormatText('0');
			}).catch((errors) => {    
				console.log(errors)  ;
				/*localStorage.clear();				
				this.$router.push('/login');*/
			});
		},
		getCurrencyText(ind) {
			var currency = this.$refs.currency.value;
			if(ind == 0){
				currency = this.currentUserPersonalInfo.settings.id_currency
			}
			for(var i in this.currencyData){
				if(this.currencyData[i].value == currency){
					this.currencyText = {iso:this.currencyData[i].iso,class:this.currencyData[i].class};
					this.ShowFormat();
					return true;
				}
			}
			this.ShowFormat();
		},
		getCurrencyFormatText(ind) {
			
			var Format = this.$refs.currency_format.value;
			if(ind == 0){
				Format = this.currentUserPersonalInfo.settings.currency_format.id_currency_format;
			}
			for(var i in this.currencyFormatData){
				if(this.currencyFormatData[i].value == Format){
					this.currencyFormatParams = this.currencyFormatData[i];
					this.ShowFormat();
					return true;
				}
			}
		},
		save() {
			const submitButton = this.$refs["kt_save_changes"];
			submitButton.classList.add("spinner", "spinner-light", "spinner-right");
			var currency = this.$refs.currency.value;
			
			//var messenger_id = this.$refs.messenger_id.value;
			var delivery = this.$refs.delivery.checked;
			var self_pickups = this.$refs.self_pickups.checked;
			var in_resto = false;
			if(this.currentUserPersonalInfo.settings.in_resto_show){
				in_resto = this.$refs.in_resto.checked;
			}
			var notif_whatsapp = this.$refs.InputNotifWhatsApp.checked;
			var notif_sms = this.$refs.notif_sms.checked;
			var notif_email = false;//this.$refs.notif_email.checked;
			var notif_messenger = this.$refs.InputMessenger.checked;
			var minimum_order_for_deliver = this.$refs.minimum_order_for_deliver.value;
			var minimum_order_for_free_delivery = this.$refs.minimum_order_for_free_delivery.value;
			var delivery_fees = this.$refs.delivery_fees.value;
			var tax = this.$refs.tax.value;
			var delivering_to = this.currentUserPersonalInfo.settings.delivering_to;
			var whatsapp_number = this.currentUserPersonalInfo.settings.whatsapp;
			if(this.$refs.whatsapp_number){
				whatsapp_number = this.$refs.whatsapp_number.value;
			}
			if(this.$refs.delivering_to){
				delivering_to = this.$refs.delivering_to.value;
			}
			if(notif_whatsapp == true){
				if(whatsapp_number == ""){
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.SALES_CHANNEL.MSG_ERROR_WA"),"error");
					return 
				}
			}
			if(notif_sms == true){
				if(this.currentUserPersonalInfo.phone_shop == ""){
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.SALES_CHANNEL.MSG_ERROR_SMS"),"error");
					return 
				}
			}
			var data = 	{
							currency:currency,
							whatsapp_number:whatsapp_number,
							//messenger_id:messenger_id,
							delivery:delivery,
							self_pickups:self_pickups,
							in_resto:in_resto,
							notif_whatsapp:notif_whatsapp,
							notif_sms:notif_sms,
							notif_email:notif_email,
							notif_messenger:notif_messenger,
							minimum_order_for_deliver:minimum_order_for_deliver,
							minimum_order_for_free_delivery:minimum_order_for_free_delivery,
							delivery_fees:delivery_fees,
							tax:tax,
							delivering_to:delivering_to
						}
			axios.post("/klik/customer/update/settings",data).then((response) => {
					console.log(response);
					this.UpdateSession;
					this.SwalShow(this.$t("APP_NAME"),this.$t("SETTINGS.SALES_CHANNEL.MSG_SUCCESS_OPERATION"),"info");
					submitButton.classList.remove(
						"spinner",
						"spinner-light",
						"spinner-right"
					);
				}).catch((errors) => {    
					console.log(errors)  ;
					localStorage.clear();				
					this.$router.push('/login');
				});
		},
		cancel() {
			this.$refs.username.value = this.currentUserAccountInfo.username;
			this.$refs.email.value = this.currentUserAccountInfo.email;
			this.$refs.language.value = this.currentUserAccountInfo.language;
			this.$refs.time_zone.value = this.currentUserAccountInfo.time_zone;
			this.$refs.email_com.checked = this.currentUserAccountInfo.communication.email;
			this.$refs.sms_com.checked = this.currentUserAccountInfo.communication.sms;
			this.$refs.phone_com.checked = this.currentUserAccountInfo.communication.phone;
			this.$refs.verification.checked = this.currentUserAccountInfo.verification;
		},
		SwalShow(title,text,icon) {
			Swal.fire({
						title: title,
						html: text,
						icon: icon,
						confirmButtonClass: "btn btn-secondary",
						heightAuto: false
					}); 
		},
	},
	computed: {
		...mapGetters(["currentUserAccountInfo"]),
		...mapGetters(["currentUserPersonalInfo"]),
		...mapGetters(["UpdateSession"]),
		...mapGetters(["numberFormatShow"]),
	}
};
</script>